<template>
    <div>
      <span v-if="row.item.status == 'On-Going'">
        <b-badge variant="success">{{ row.item.status }}</b-badge>
      </span>
      <span v-if="row.item.status == 'Done'">
        <b-badge variant="primary">{{ row.item.status }}</b-badge>
      </span>
      <span v-if="row.item.status == 'Cancelled'">
        <b-badge variant="danger">{{ row.item.status }}</b-badge>
      </span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'inventory-row-status',
    props: {
      row: {
        type: Object,
        required: true
      }
    }
  };
  </script>