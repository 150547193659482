<template>
	<b-card>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		<b-tabs>
			<b-tab title="Primary Information" active>
				<b-card-text>
					<b-row class="mb-2">
						<b-col sm="7">
							<b-form-group label="Company" label-cols-sm="4" label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ row.item.company }}
							</b-form-group>

							<b-form-group label="Storage Location" label-cols-sm="4" label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ row.item.storageLocation }}
							</b-form-group>

							<b-form-group label="Scanner" label-cols-sm="4" label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ getUserDisplay(row.item.userId) }}
							</b-form-group>

							<b-form-group label="Description" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<span class="truncate-text">
									<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
										type="html" :text="row.item.description" />
								</span>
							</b-form-group>

							<b-form-group label="Date Created" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ showFormattedDate(row.item.dateCreated) }}
							</b-form-group>
							<b-form-group label="Created By" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ getUserDisplay(row.item.createdBy) }}
							</b-form-group>

							<b-form-group label="Date Updated" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ showFormattedDate(row.item.dateUpdated) }}
							</b-form-group>
							<b-form-group label="Updated By" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ getUserDisplay(row.item.updatedBy) }}
							</b-form-group>

							<b-form-group label="Status" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<InventoryRowStatus :row="row"/>
							</b-form-group>
							
						</b-col>
					</b-row>
				</b-card-text>
			</b-tab>

            <b-tab title="Assets">
				<InventoryDetailsAssetView :session="row.item" />
			</b-tab>
		</b-tabs>

		<b-row class="mt-4 mb-2">
			<b-col sm="12" class="text-sm-right">
				<b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
					<i class="icon-arrow-up"></i>
				</b-button>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
// Components
import InventoryDetailsAssetView from './InventoryDetailsAssetView';
import InventoryRowStatus from './InventoryRowStatus.vue';

// Util
import { DateUtil } from '@/utils/dateutil';
import { UserUtil } from '@/utils/userutil';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';

export default {
	name: 'inventory-details-view',
	components: {
		InventoryDetailsAssetView,
		InventoryRowStatus,
		Loading,
		truncate,
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
            // Check for loader
			isLoading: false,
		};
	},
	mounted() { },
	methods: {
        // UTILS
        showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getUserDisplay(userId) {
			return UserUtil.getUserDisplay(this.allUsersObj, userId);
		},
	},
};
</script>

<style scoped>
.caption {
	font-style: italic !important;
	font-size: medium !important;
}

.total {
	margin-top: 2px;
	font-size: medium !important;
	font-weight: 500;

}

.main-total {
	font-weight: 480;
}

.manual {
	color: #30363b !important;
}

.scan {
	color: #21a8d8 !important;
}

.asset-pill {
	font-size: 14px !important;
}

.see-more {
	font-size: 11px !important;
}
</style>
