<template>
    <div>
         <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">INVENTORY ASSETS</span>
                <div class="details-view-subtitle">List of inventory count per asset type</div>
            </b-col>
            <b-col sm="6" md="5" offset-sm="6" offset-md="1" class="text-md-right mb-2">
                <b-input-group align="right" class="pagination" prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection">

            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>

            <template v-slot:cell(actual)="row">
                <span class="numFont">
                    {{ row.item.actual.toLocaleString() }}
                </span>
            </template>

            <template v-slot:cell(description)="row">
                <span class="truncate-text">
                    <truncate collapsed-text-class="collapsed" clamp="Show More" :length="50" less="Show Less"
                        type="html" :text="breakDescription(row.item.description, 25)" />
                </span>
            </template>

        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Others
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
    name: 'inventory-details-asset-view',
    components: {
        truncate
    },
    props: {
        session: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'index',
                    label: '#',
                },
                {
                    key: 'name',
                    label: 'Asset Type',
                },
                {
                    key: 'description',
                    label: 'Description'
                },
                {
                    key: 'actual',
                    label: 'Actual',
                    class: 'text-right'
                }
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
        }
    },
    mounted() {
        this.updateTable();
    },
    methods: {
        updateTable() {
            let item = this.session;
            let assetsObj = {};

            if (!_.isEmpty(item.assets)) {
                _.forEach(item.assets, (asset) => {
                    let assetEntry = {
                        id: asset.assetTypeId,
                        name: asset.assetType,
                        actual: asset.actualQuantity,
                        description: asset.description
                    };

                    assetsObj[asset.assetTypeId] = assetEntry;
                });
            }

            this.items = Object.values(assetsObj);
            this.totalRows = this.items.length;
        },

        breakDescription(description, length) {
			return description.length > length ? description.replace(new RegExp(`(?![^\\n]{1,${length}}$)([^\\n]{1,${length}})\\s`, 'g'), '$1<br>') : description;
		}
    }
}
</script>