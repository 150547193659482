import { db } from '@/config/firebase';


function generateQuery(filter) {
    let query = db.collection("inventorySessions");

    if (filter.sessionId && filter.sessionId.length > 0) {
        query = query.where("sessionId", "==", filter.sessionId);
    } else {
        if (filter.fromTimestamp && filter.fromTimestamp > 0) {
            query = query.where("dateCreated", ">=", filter.fromTimestamp);
        }

        if (filter.toTimestamp && filter.toTimestamp > 0) {
            query = query.where("dateCreated", "<=", filter.toTimestamp);
        }

        if (filter.company && filter.company.id && filter.company.id.length > 0) {
            query = query.where("companyId", "==", filter.company.id);
        }

        if (filter.status && filter.status.length > 0) {
            query = query.where("status", "==", filter.status);
        }

        if (filter.user && filter.user.id && filter.user.id.length > 0) {
            query = query.where("userId", "==", filter.user.id);
        }
    }

    return query;
}

function getInventorySessionListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            callback(change.type, change.doc.data());
        });
    });
    return listener;
}

async function getInventorySessions(companyId, userId) {
    let sessionsObj = {};

    const query = db.collection('inventorySessions')
        .where("companyId", "==", companyId)
        .where("userId", "==", userId);
    const querySnapshot = await query.get();

    querySnapshot.forEach((doc) => {
        let sessionObj = doc.data();
        sessionObj.id = doc.id;
        sessionsObj[doc.id] = sessionObj;
    });

    return sessionsObj;
}

export default {
    getInventorySessionListener,
    getInventorySessions
}