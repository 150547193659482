<template>
    <b-modal id="mark-as-done-inventory-session" title="Mark as Done" size="lg" ref="modal" ok-title="Done"
		@ok="handleOk" @show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-container fluid>
			<div>
				<b-row class="mb-3">
					<b-col sm="12">
						Are you sure you want to mark session <strong class="numFont">{{ sessionId }}</strong> as <strong>done</strong>?
					</b-col>
				</b-row>

				<b-card>
					<b-row class="mt-0 mb-2">
						<b-col sm="8">
						<b>PRIMARY INFORMATION</b>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col lg="6" md="6" sm="12" class="my-1">
							<b-form-group label="COMPANY" label-class="font-weight-bold pt-0">
								{{ inventorySession.company }}
							</b-form-group>
							<b-form-group label="SCANNER" label-class="font-weight-bold pt-0">
								{{ inventorySession.user + ' (' + inventorySession.userId + ')' }}
							</b-form-group>
						</b-col>
						<b-col lg="6" md="6" sm="12" class="my-1">
							<b-form-group label="STORAGE LOCATION" label-class="font-weight-bold pt-0">
								{{ inventorySession.storageLocation }}
							</b-form-group>
							<b-form-group label="DESCRIPTION" label-class="font-weight-bold pt-0">
								<span class="truncate-text">
								<truncate collapsed-text-class="collapsed" clamp="Show More" less="Show Less"
									type="html" :text="breakRemarks(description, 50)" :length="100" />
								</span>
							</b-form-group>
						</b-col>
					</b-row>
				</b-card>

				<b-card>
					<InventoryDetailsAssetView :session="inventorySession"/>
				</b-card>

				<b-row>
                    <b-col sm="12">
                        <span class="info" v-html="getConfirmApproveTxt()"></span>
                    </b-col>
                </b-row>
			</div>
		</b-container>
	</b-modal>
</template>

<script>
// Components
import InventoryDetailsAssetView from './InventoryDetailsAssetView';

// Util
import { DateUtil } from '@/utils/dateutil';
import { InventorySessionUtil } from '@/utils/inventorySessionUtil';

// API & Database
import sessionAPI from '@/api/inventorySessionApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from "vue-truncate-collapsed";

export default {
	name: 'mark-as-done-inventory-session',
    components: {
		InventoryDetailsAssetView,
		Loading,
		truncate
	},
    props: {
		allAssetTypesObj: {
			type: Object,
			required: true,
		}
	},
    data() {
		return {
			inventorySession: {},
			currUserId: this.$store.getters.loggedUser.id,

			// Check for loader
			isLoading: false,
		};
	},
    computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		sessionId() {
			return this.inventorySession && this.inventorySession.sessionId ? this.inventorySession.sessionId : '-';
		},
		description() {
			return this.inventorySession && this.inventorySession.description ? this.inventorySession.description : '';
		}
	},
    mounted() {
		EventBus.$on('onUpdateInventorySession', (inventorySession) => {
			this.inventorySession = InventorySessionUtil.cleanupFields(inventorySession);
		});
	},
    methods: {
		getConfirmApproveTxt() {
            return '<i class="fa fa-info-circle" aria-hidden="true"></i> By clicking <strong>Done</strong>, you will no longer be able to edit this inventory session.'
        },
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			await this.markAsDone();
		},
        async markAsDone() {
			try {
				let { data } = await sessionAPI.markInventorySessionAsDone(
					this.inventorySession,
					this.currUserId,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseSaveSession', data.inventorySession);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(`Error marking as done Inventory Session "${this.sessionId}". Please try again.`);
				}
			} catch (_error) {
				this.$toaster.error(`Error marking as done Inventory Session "${this.sessionId}". Please try again.`);
			} finally {
                // hide loading indicator
                this.isLoading = false;
            }
		},
		onReset() {
			// do nothing
		},

		// UTILS
		breakRemarks(remarks, length) {
			return remarks.length > length
				? remarks.replace(new RegExp(`([^\\s]{${length}})`, "g"), "$1<br>")
				: remarks;
		},
    },
    beforeDestroy() {
		EventBus.$off('onUpdateInventorySession');
	},
}
</script>

<style scoped>
</style>